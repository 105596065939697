<template>
  <div class="flex-grow-1 pb-12">
    <AdminTitle title="Import Users">
      <template v-slot:actions>
        
      </template>
    </AdminTitle>

    <div>
      <v-form ref="form" class="d-flex align-center gap-10">
        <v-file-input
          v-model="csv"
          placeholder="Select CSV file"
          :rules="[rules.required]"
          accept=".csv"
          hide-details
          outlined
          dense
        ></v-file-input>

        <v-btn 
          @click="converToJson()"
          :disabled="!csv" 
          :loading="importing" 
          color="accent gradient"
        >Upload</v-btn>
      </v-form>

      <div class="mt-5">Download template <a href="#" class="text-decoration-none underline-on-hover info--text" @click.prevent="$downloadTemplate()">here</a>.</div>
    </div>

    <div v-if="progress.length" class="mt-5">
      <div v-for="(prog, i) in progress" :key="i">
        {{ prog }}
      </div>
    </div>
  </div>
</template>

<script>
import csv from 'csvtojson'
import rules from '@/rules'
import firebase from 'firebase'
import generator from 'generate-password'
import db from '@/firebase/init' 

export default {
  metaInfo: {
    title: 'Import Users'
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      csv: null,
      importing: false,
      progress: [],
      counter: 0,
      totalCount: 0
    }
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    /*------------------------------------------------------------------------------
     * CONVERT TO JSON
     *----------------------------------------------------------------------------*/
    converToJson() {
      let file = this.csv
      let fr = new FileReader()
      fr.readAsText(file)
      this.importing = true

      fr.onload = () => {
        if (this.$refs.form.validate()) {
          csv()
          .fromString(fr.result)
          .then(async (jsonObj) => {
            if (jsonObj.length) {
              this.totalCount = jsonObj.length

              jsonObj.forEach(async data => {
                var user = {
                  firstName: data['First Name'],
                  lastName: data['Last Name'],
                  email: data['Email Address'],
                  phone: data['Phone'],
                  experience: data['Years Experience'],
                  position: data['Position'],
                  skillets: data['Skillsets (Trained)'],
                  role: 'jobseeker',
                  password: generator.generate({length: 10, numbers: true}),
                  createdAt: Date.now(),
                  updatedAt: Date.now()
                }

                await db.collection('users')
                .where('email', '==', user.email)
                .limit(1).get()
                .then(async (snapshot) => {
                  this.counter++

                  if (!snapshot.size) {
                    var importuser = firebase.functions().httpsCallable('user-importUsers')
                    this.progress.push(`Create user ${user.email}...`)
                    
                    await importuser({ user })
                    .then(async () => {

                      await this.sendEmail(user)

                      if (this.totalCount == this.counter) {
                        this.$store.dispatch('showSuccess', 'Users successfully imported')
                        this.importing = false
                      }
                    })
                    .catch(error => {
                      console.log(error.message)
                    })
                  }
                  else {
                    var updatePassword = firebase.functions().httpsCallable('user-updatePassword')
                    var doc = snapshot.docs[0]

                    await updatePassword({ uid: doc.userid, password: user.password })
                    .then(async () => {

                      await this.sendEmail(user)

                      if (this.totalCount == this.counter) {
                        this.$store.dispatch('showSuccess', 'Users successfully imported')
                        this.importing = false
                      }
                    })
                    .catch(error => {
                      console.log(error.message)
                    })
                    
                    this.progress.push(`User ${user.email} already exists`)
                    if (this.totalCount == this.counter) {
                      this.$store.dispatch('showSuccess', 'Users successfully imported')
                      this.importing = false
                    }
                  }
                })
                .catch(error => {
                  this.totalCount--
                  console.log(error.message)
                })
              })
            }
          })
        }
      }
    },

    async sendEmail(user) {
      let message = `
        <p>Hi ${user.firstName},</p>
        
        <p>As an existing EPC member we’re excited to give you the opportunity to be first in line to be discovered by more schools through our new service about to launch, called TeacherFinder. </p>
        
        <p>TeacherFinder is a new web-based service that helps to connect educators with employers looking for contract teachers (think Airtasker.com but dedicated entirely to teacher employment!). It’s 100% free for teachers (and very competitively priced for employers) so once word gets out we expect sign-up rates will be BIG!</p>
        
        <p>With a TeacherFinder profile you’ll be able to access additional contract opportunities beyond what our dedicated EPC team can offer you. Your TeacherFinder profile will allow you to: </p>
        <ul>
          <li>Promote your skills and experience to even more local schools looking for teachers for contracts (not just EPC school partners)</li>
          <li>Search for new contract opportunities Australia-wide</li>
          <li>Control your own availability, what jobs you see and what jobs you get offered</li>
          <li>Accept or decline job offers online </li>
          <li>Skip the resume drop – let local schools contact you! </li>
        </ul>
        
        <p>So, if you want to be one of the first to be able to search for (and be found for) teaching contracts you simply need to sign-in using the details below and complete your profile before 30 September 2021 (you’ll see we’ve put in some of your basic information to make it that bit easier to get started).</p>
        
        <p>Not interested for now? No worries. You’re welcome to set up your own account anytime down the track by going to www.teacherfinder.com.au .<br>
        Here are your login details to get started:</p>
        
        <p>URL: ${window.location.origin}/login <br>
        Account: ${user.email} <br>
        Password: ${user.password}</p>
        
        <p>Once you login the first time, simply change your password by going to ‘Account Settings’.</p>
        
        <p>We recommend you login and set up your profile as soon as you can. The sooner you register the more likely you’ll be top of the list when employers search!</p>
        
        <p>TeacherFinder’s customer support team are here to help you with whatever you need, simply email support@teacherfinder.com.au or contact us via the live chat option on the app ${window.location.origin}.</p>
        
        <p>We look forward to helping you secure even more contract opportunities!</p>
        
        <p>Regards,<br>
        The Team at TeacherFinder<br>
        </p>
        <small>PS - While TeacherFinder is a service of EPC’s parent company (EPC Capital Pty Ltd), both EPC and TeacherFinder will operate as separate entities in their own right. Regardless of whether you sign up with TeacherFinder, as an EPC member you will continue to receive great, personalised service for supply and contract placements at EPC’s partnering school partners from EPC’s dedicated support team.</small>
      `

      let messageData = {
        message,
        subject: 'Exclusive offer to EPC members – early access to TeacherFinder ',
        recipient: user.email
      }

      console.log(user.email)

      await this.$store.dispatch('notifications/send', messageData)
    }
  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.$store.dispatch('')
  }
}
</script>